import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Translator from "../components/Translator";

const IconTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        sx={{
          width: "100%",
          display: "flex", // Directly target the indicator.
          ".MuiTabs-indicator": {
            backgroundColor: "black", // Change the indicator color to black
          },
        }}
      >
        {/* <Tab label="Task" sx={{ flex: 1 }} /> */}
        <Tab
          label="Translator"
          sx={{
            flex: 1,
            color: "black",
            "&.Mui-selected": {
              // Style for selected state
              color: "black", // Ensure text color is black when selected
            },
            "&:hover": {
              // Optional: style for hover state
              color: "black", // Ensure text color is black on hover
              opacity: 1,
            },
          }}
        />
        {/* <Tab label="Hint" sx={{ flex: 1 }} /> */}
      </Tabs>
      {/* {value === 0 && <Task />} Render Task component if value is 0 */}
      {value === 0 && <Translator />}
      {/* Render Translator component if value is 1 */}
      {/* {value === 2 && <Guide />} Render Guide component if value is 2 */}
    </div>
  );
};

const Toolkit = () => {
  return (
    <div className="md:grow md:max-w-xs flex h-screen flex-col">
      <IconTabs />
    </div>
  );
};

export default Toolkit;
