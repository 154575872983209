import { Message } from "../types";
import { useState, useContext } from "react";
import Feedback from "./Feedback";
import comment from "../assets/icons/comment.svg";
import recall from "../assets/icons/recall.svg";
import { MessagesContext } from "../context/messages.context";

const HumanMessage = ({ message }: { message: Message }) => {
  const [feedback, setFeedback] = useState<string>("");
  const { conversationId, recallMessages } = useContext(MessagesContext);
  const handleFeedback = async () => {
    if (feedback) return;
    const response = await fetch(
      "https://bk-intl-codeswi-backend-beccbwtqbt.us-west-1.fcapp.run/feedback",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: message.content,
        }),
      }
    );
    if (response.body !== null) {
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        setFeedback((prev) => prev + value);
      }
    } else {
      console.error("Response body is null.");
    }
  };

  const handleRecall = async () => {
    recallMessages(conversationId, message.id);
  };

  return (
    <div className="flex justify-end p-3 rounded-2xl">
      <div className="max-w-md text-[#1f2531] bg-white rounded-2xl border-2 border-black">
        <div className="p-3">{message.content}</div>
        {!feedback && (
          <div className="flex justify-end mt-2 space-x-2 mr-2 mb-2 ">
            <img
              className="w-5 h-5 cursor-pointer"
              onClick={handleFeedback}
              src={comment}
              alt="comment"
            />
            <img
              className="w-5 h-5 cursor-pointer"
              onClick={handleRecall}
              src={recall}
              alt="recall"
            />
          </div>
        )}
        {feedback && <Feedback content={feedback} />}
      </div>
    </div>
  );
};

export default HumanMessage;
