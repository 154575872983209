import React, { useState } from "react";
import { MenuItem, FormControl, Select, InputLabel } from "@mui/material";

function LanguageMenu({ onSelect }: { onSelect: (lang: string) => void }) {
  const [language, setLanguageLocal] = useState("fr");

  const handleChange = (event: any) => {
    const selectedLanguage = event.target.value;
    setLanguageLocal(selectedLanguage);
    onSelect(selectedLanguage);
  };

  return (
    <div style={{ marginLeft: "10px" }}>
      <FormControl size="small">
        <InputLabel id="language-select-label"></InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={language}
          label="Language"
          onChange={handleChange}
        >
          <MenuItem value="fr">🇫🇷 French</MenuItem>
          <MenuItem value="es">🇪🇸 Spanish</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default LanguageMenu;
