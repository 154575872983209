const ConversationStarter = ({ onStart }: { onStart: () => Promise<void> }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <button
        onClick={onStart}
        className="mt-4 px-6 py-3 rounded-md bg-black text-white hover:bg-blue-600 focus:outline-none"
        style={{ backgroundColor: "#000000" }}
      >
        Let's Go!
      </button>
    </div>
  );
};

export default ConversationStarter;
