export async function playAudiOnceFromURL(url: string) {
  const audio = new Audio("https://" + url);
  audio.play();
}

export async function playAudioFromText(text: string) {
  const API_PATH =
    "https://api.elevenlabs.io/v1/text-to-speech/16MLbcUl6cEK7QlO0FuD";

  const OPTIONS = {
    method: "POST",
    body: JSON.stringify({
      text: text,
      model_id: "eleven_multilingual_v2",
    }),
    headers: {
      "xi-api-key": "44cf4910f8b3850843194a3f2f9e02cb",
      "Content-Type": "application/json",
      accept: "audio/mpeg",
    },
  };

  const response = await fetch(API_PATH, OPTIONS);
  const audioData = await response.arrayBuffer();
  const audioContext = new window.AudioContext();
  const audioBuffer = await audioContext.decodeAudioData(audioData);
  const audioSource = audioContext.createBufferSource();
  audioSource.buffer = audioBuffer;
  audioSource.connect(audioContext.destination);
  audioSource.start(0);
}
