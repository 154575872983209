import { createContext, useState, useEffect } from "react";
import {
  onAuthStateChangedListener,
  createUserDocumentFromAuth,
} from "../utils/firebase";

import { User, UserContextType } from "../types";
import { CircularProgress } from "@mui/material";

export const UserContext = createContext<UserContextType>({
  setCurrentUser: () => null,
  currentUser: null,
});

export const UserProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const value: UserContextType = { currentUser, setCurrentUser };
  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user: any) => {
      if (user) {
        createUserDocumentFromAuth(user);
        const { uid, displayName, photoURL, email } = user;
        const signedInUser: User = {
          uid,
          email,
          photoURL,
          displayName,
          systemRole: "user",
        };
        setCurrentUser(signedInUser);
      } else {
        setCurrentUser(null);
      }
      setLoading(false); // Authentication state resolved
    });
    return unsubscribe;
  }, []);

  if (loading) {
    // You can render a loading state here
    return <CircularProgress />;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
