import { useContext } from "react";
import { UserContext } from "../context/user.context";
import { MessagesContext } from "../context/messages.context";
import { signOutUser } from "../utils/firebase";

const UserProfile = () => {
  const { currentUser } = useContext(UserContext);
  const { clearMessages } = useContext(MessagesContext);
  const signOutHandler = async () => {
    await signOutUser();
    clearMessages();
    localStorage.removeItem("cachedConversationData");
  };
  return (
    <div className="flex flex-col items-center mt-auto p-4">
      <div className="flex items-center space-x-8">
        <img
          src={currentUser?.photoURL}
          className="w-10 h-10 mr-2 rounded-full"
          alt="User"
        />
        <h2>{currentUser?.displayName}</h2>
        <button
          onClick={signOutHandler}
          className="px-3 py-1 bg-black text-white rounded"
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
