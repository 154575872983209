const Feedback = ({ content }: { content: string }) => {
  const renderBoldText = (text: string) => {
    // Regular expression to match text enclosed in double asterisks (**)
    const boldTextRegex = /\*\*(.*?)\*\*/g;

    // Split the text based on the regular expression and render bold parts
    return text.split(boldTextRegex).map((part, index) => {
      if (index % 2 === 0) {
        // Even index means regular text, return it as-is
        return part;
      } else {
        // Odd index means bold part, wrap it in a <span> with font-weight: bold
        return (
          <span key={index} style={{ fontWeight: "bold" }}>
            {part}
          </span>
        );
      }
    });
  };

  return (
    <div className="p-1.5 w-full">
      <div className="text-[#1f2531] bg-[#f6f6f6] rounded-2xl mt-3 relative w-full">
        <div className="mt-1 p-3">
          <span className="font-bold">Feedback:</span> {renderBoldText(content)}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
