import { useEffect, useRef } from "react";
import { Message } from "../types";
import AgentMessage from "./AgentMessage";
import HumanMessage from "./HumanMessage";
import { Skeleton } from "@mui/material";

const ChatMessages = ({
  messages,
  awaitResponse,
}: {
  messages: Message[];
  awaitResponse: boolean;
}) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "auto" });
  }, [messages]);

  return (
    <div className="w-full flex-1 flex flex-col overflow-y-auto p-4 space-y-4">
      {messages.map((item) => {
        if (item.role === "assistant") {
          return <AgentMessage message={item} key={item.id} />;
        } else if (item.role === "user") {
          return <HumanMessage message={item} key={item.id} />;
        } else {
          return null;
        }
      })}
      {awaitResponse && (
        <Skeleton
          variant="rectangular"
          className="flex w-[90%] max-w-md flex-col bg-[#f6f6f6] p-3 rounded-2xl min-h-[100px]"
        />
      )}
      <div ref={bottomRef} />
    </div>
  );
};

export default ChatMessages;
