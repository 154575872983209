import { Modal, Typography, Button, Box } from "@mui/material";
import { signInWithGooglePopup } from "../utils/firebase";

const SignInModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const handleLogin = async () => {
    await signInWithGooglePopup();
    onClose();
  };
  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const modalContentStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    textAlign: "center" as "center", // Specify the type explicitly
  };

  const loginInButtonStyle = {
    marginRight: "10px", // Add spacing between buttons
    padding: "5px 10px", // Smaller button size
    backgroundColor: "#88abdfaf", // Colored login button
    color: "white", // Text color
  };

  const closeButtonStyle = {
    marginRight: "10px", // Add spacing between buttons
    padding: "5px 10px", // Smaller button size
    backgroundColor: "black", // Colored login button
    color: "white", // Text color
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={modalStyle}
      >
        <div style={modalContentStyle}>
          <Typography variant="h6" id="modal-modal-title">
            Login to continue
          </Typography>
          {/* <Typography variant="body1" id="modal-modal-description">
            Please log in to continue your conversation.
          </Typography> */}
          <Box mt={2}>
            {" "}
            {/* Add spacing between title, text, and buttons */}
            <Button
              variant="contained"
              style={loginInButtonStyle}
              onClick={handleLogin}
            >
              Google Login
            </Button>
            <Button
              variant="contained"
              style={closeButtonStyle}
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default SignInModal;
