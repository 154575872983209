import { useEffect, useState } from "react";
import { translate } from "../utils/api";
import { Message } from "../types";

const AgentMessage = ({ message }: { message: Message }) => {
  const [translation, setTranslation] = useState("");
  const [messageState, setMessageState] = useState<
    "blur" | "text" | "translate"
  >("blur");

  const ACTIVE_BUTTON_CLASS = "bg-black text-white";
  const NOT_ACTIVE_BUTTON_CLASS = "bg-white";

  const handleBlurText = () => {
    setMessageState("blur");
  };

  const handleShowText = () => {
    setMessageState("text");
  };

  const handleTranslate = async (): Promise<void> => {
    setMessageState("translate");
    if (translation) return;
    const response = await fetch(
      "https://bk-intl-codeswi-backend-beccbwtqbt.us-west-1.fcapp.run/translation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: message.content,
        }),
      }
    );
    if (response.body !== null) {
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        setTranslation((prev) => prev + value);
      }
    } else {
      console.error("Response body is null.");
    }
  };

  return (
    <div>
      <div className="flex w-[90%] max-w-md flex-col bg-grey-200 bg-[#f6f6f6] p-3 rounded-2xl space-y-2 border-2 border-grey">
        <audio src={"https://" + message.audioUrl} controls />
        <div className="divide-y">
          {messageState === "blur" && (
            <div className="blur-sm">{message.content}</div>
          )}
          {messageState === "text" && <div>{message.content}</div>}
          {messageState === "translate" && (
            <div className="pt-1 mt-1">{translation}</div>
          )}
        </div>
        <div className="flex justify-end mt-1 space-x-2 ">
          <button
            onClick={handleBlurText}
            className={`rounded-lg px-3 py-1  ${
              messageState === "blur"
                ? ACTIVE_BUTTON_CLASS
                : NOT_ACTIVE_BUTTON_CLASS
            }`}
          >
            Blur
          </button>
          <button
            onClick={handleShowText}
            className={`rounded-lg px-3 py-1 ${
              messageState === "text"
                ? ACTIVE_BUTTON_CLASS
                : NOT_ACTIVE_BUTTON_CLASS
            }`}
          >
            Text
          </button>
          <button
            onClick={handleTranslate}
            className={`rounded-lg px-3 py-1 ${
              messageState === "translate"
                ? ACTIVE_BUTTON_CLASS
                : NOT_ACTIVE_BUTTON_CLASS
            }`}
          >
            Translate
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentMessage;
