import Menu from "./routes/Menu";
import Chat from "./routes/Chat";
import Toolkit from "./routes/Toolkit";

function App() {
  return (
    <div className="flex h-screen divide-x">
      <Menu />
      <Chat />
      <Toolkit />
    </div>
  );
}

export default App;
