import SiteHeader from "../components/SiteHeader";
import ChatThread from "../components/ChatThread";
import SignIn from "../components/SignIn";
import UserProfile from "../components/UserProfile";

import { useContext } from "react";
import { UserContext } from "../context/user.context";

const Menu = () => {
  const { currentUser } = useContext(UserContext);
  return (
    <div className="md:grow md:max-w-xs flex h-screen flex-col">
      <SiteHeader />
      <ChatThread />
      {currentUser ? <UserProfile /> : <SignIn />}
    </div>
  );
};
export default Menu;
