import ProfilePic from "../assets/profile/zorintha.png";

const ChatThread = () => {
  return (
    <div className="w-full p-4 ">
      <div className="w-full h-16 px-2 md:bg-black text-white md:flex md:align-center md:items-center md:rounded-xl">
        <img
          className="h-10 w-10 rounded-md md:rounded-full"
          src={ProfilePic}
          alt="profile"
        />
        <div className="flex-1 md:flex md:justify-center md:items-center hidden">
          <p>Zorintha</p>
        </div>
      </div>
    </div>
  );
};

export default ChatThread;
