import Logo from "../assets/logo/cs_logo.png";

const SiteHeader = () => {
  return (
    <div className="flex h-12 w-full px-2 items-center justify-center md:justify-start">
      {/* <img className="logo w-8 h-8" src={Logo} alt="logo" /> */}
    </div>
  );
};

export default SiteHeader;
